import React from "react";
import domains from "../../../configuration/domains";
import { navigate } from "gatsby";

import * as s from "./styles.module.scss";

const { imageCdnUrl } = domains;

export default function Sidebar() {
    const visitUrl = (url: string) => {
        window.location.href = url;
    };

    return (
        <div className={s.sidebar}>
            <div
                className={[s.imageWrapper, s.deepSky].join(" ")}
                onClick={() => navigate("/deep-sky")}
            >
                <h3>DEEP SKY</h3>
                <img
                    src={`${imageCdnUrl}/Banners/ORION_NEBULA_EXMOOR_A-denoise-X10-denoise-TABLET_xaardg.jpg`}
                    title="Deep Sky photographs"
                    alt="Deep Sky photographs"
                    className={s.image}
                />
            </div>
            <div
                className={[s.imageWrapper, s.starScape].join(" ")}
                onClick={() => navigate("/starscape")}
            >
                <h3>STARSCAPE</h3>
                <img
                    src={`${imageCdnUrl}/Banners/MILKY_WAY_SUPER_WIDE_FIELD_IRIA_GREECE_SEP_2019_MOBILE_gtiypu.jpg`}
                    title="Starscape photographs"
                    alt="Starscape photographs"
                    className={s.image}
                />
            </div>

            <div
                className={[s.imageWrapper, s.solarSystem].join(" ")}
                onClick={() => navigate("/solar-system")}
            >
                <h3>SOLAR SYSTEM</h3>
                <img
                    src={`${imageCdnUrl}/Banners/95_Mineral_Moon_XS_fsi7bu.jpg`}
                    title="Solar system photographs"
                    alt="Solar system photographs"
                    className={s.image}
                />
            </div>

            <div
                className={[s.imageWrapper, s.events].join(" ")}
                onClick={() => navigate("/events")}
            >
                <h3>EVENTS</h3>
                <img
                    src={`${imageCdnUrl}/Banners/events_banner_METEORS_MOBILE_ueboec.jpg`}
                    title="Various astronomical events photographs"
                    alt="Various astronomical events photographs"
                    className={s.image}
                />
            </div>
        </div>
    );
}
