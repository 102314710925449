import React from "react";

import domains from "../../../configuration/domains";
import Heading from "../../atoms/Heading";
import Page from "../../organisms/Page";
import Sidebar from "../../molecules/Sidebar";

import { email } from "../../../configuration/contact";

import * as p from "../../organisms/Page/styles.module.scss";
//import * as s from "./styles.module.scss";

const { baseUrl, imageCdnUrlOpt } = domains;

export default function Contact() {
    return (
        <Page
            metaTitle="Contact me"
            canonicalUrl={`${baseUrl}/contact`}
            metaDescription="Contact astrophotographer Panagiotis Andreou."
            heroImages={[
                `${imageCdnUrlOpt}/Milky_Way/Milky_Way_Paros_0818_M_gsr1gx.jpg`, // 768 Mobile
                `${imageCdnUrlOpt}/Milky_Way/Milky_Way_Paros_0818_M_gsr1gx.jpg`, // 992 Tablet
                `${imageCdnUrlOpt}/Milky_Way/Milky_Way_Paros_0818_L_ij0bon.jpg`, // 2880 Desktop
            ]}
            isFullBanner={false}
        >
            <div className={p.containerWrapper}>
                <div className={p.container}>
                    <Heading level={1} size="large" className={p.heading}>
                        Contact me
                    </Heading>
                    <p>
                        Get in touch, send me an{" "}
                        <a href={"mailto:" + email}>email</a>.<br />
                        Note: Prints available on request, please send me an
                        email.
                    </p>
                    <p>
                        You can also follow me on various social networks and
                        platforms, such as:
                        <ul>
                            <li>
                                <a
                                    href="https://twitter.com/Weigruf"
                                    target="blank"
                                >
                                    Twitter
                                </a>{" "}
                                (regulargy updated with bonus unreleased images
                                and other astrophotography / astronomy posts)
                            </li>
                            <li>
                                <a
                                    href="https://www.facebook.com/astrotakis/"
                                    target="blank"
                                >
                                    Facebook
                                </a>{" "}
                                (regulargy updated with bonus and unreleased
                                images)
                            </li>
                            <li>
                                <a
                                    href=" https://www.instagram.com/astrotakis"
                                    target="blank"
                                >
                                    Instagram
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.flickr.com/people/157568575@N07/"
                                    target="blank"
                                >
                                    Flickr
                                </a>
                            </li>
                            <li>
                                <a
                                    href=" https://www.astrobin.com/users/Weigruf/"
                                    target="blank"
                                >
                                    Astrobin
                                </a>
                            </li>
                        </ul>
                    </p>
                </div>
                <Sidebar />
            </div>
        </Page>
    );
}
